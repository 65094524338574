<template>
  <el-card class="card_content" shadow="never">
    <div slot="header">
      <span class="iconfont icon-SystemConfig">&nbsp;点播配置</span>
    </div>
    <el-form :model="form" ref="form" label-width="200px" size="small" style="width:600px">
      <el-form-item label="豆瓣查询电影API">
        <el-input v-model="form.vod_movie_api"></el-input>
      </el-form-item>
      <el-form-item label="电影试看（分钟）">
        <el-input-number v-model.number="form.vod_see_minutes" :min="0"></el-input-number>
      </el-form-item>
      <el-form-item label="电视剧试看（集数）">
        <el-input-number v-model.number="form.vod_see_set_number" :min="0"></el-input-number>
      </el-form-item>
      <el-form-item label="单片过期（小时）">
        <el-input-number v-model.number="form.vod_film_expire" :min="0"></el-input-number>
      </el-form-item>
      <el-form-item label="电视剧过期（天数）">
        <el-input-number v-model.number="form.vod_tv_play_expire" :min="0"></el-input-number>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" style="width:100px" @click="onSubmitEdit">保存</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import { getSystemConfigApi, editSystemConfigApi } from "../../../api/data";
export default {
  data() {
    return {
      form: {
        vod_movie_api: "",
        vod_see_minutes: "",
        vod_see_set_number: "",
        vod_film_expire: "",
        vod_tv_play_expire: ""
      }
    };
  },
  created() {
    this.initVod();
  },
  methods: {
    initVod() {
      getSystemConfigApi({ type: "vod" }).then(res => {
        if (res) {
          this.form = res.data;
        }
      });
    },
    onSubmitEdit() {
      editSystemConfigApi(this.form).then(res => {
        if (res) this.$message.success(res.msg);
      });
    }
  }
};
</script>
<style lang='scss'>
</style>